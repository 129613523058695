/**
 * HANDLE-LOGIN
 * Save session and redirect after login
 */

import { instanceBackend, instanceQrcode, instanceReporting } from "@/App"
import axios from "axios"
import { store } from "@/index"
import cookie from "react-cookies"
import { accountFetchLite_AsSuperadmin, accountGet } from "@/redux/account.actions"
import { Account, AccountAdsOptions } from "@/redux/account.types"
import { userActivate, userEditAndUpdate } from "@/redux/user.actions"
import { User } from "@/redux/user.types"
import { _authReporting } from "@/redux/_auth.actions"
import { sessionGet } from "@/redux/_session.actions"
import { INTERFACE_TYPES, Session, SESSION_LANGUAGES } from "@/redux/_session.types"
import i18n from "@/translate/i18n"
import { v4 as uuid } from "uuid"
import { filterGetObserverAttributes } from "@/redux/filter.actions"
import ceils from "@/assets/ceils.json"
import env from "@/env"
import { packFetch, packGet } from "@/redux/pack.actions"
import { subscriptionFetch, subscriptionGet } from "@/redux/subscription.actions"
import { actionPlanEditSurveyReferenceId } from "@/redux/action-plan.actions"

const handleLogin = async (
  response: any,
  interfaceType: INTERFACE_TYPES,
  connectedAsSupervisor: boolean,
  authLevel: number,
  searchParams: URLSearchParams,
  lg: null | string,
  isFirstConnexion: boolean
) => {
  //If token provided
  if (response.token) {
    //Save token
    cookie.save("idtreeToken", response.token, { path: "/" })
    instanceBackend.defaults.headers.common["idtreeToken"] = response.token
    instanceQrcode.defaults.headers.common["idtreeToken"] = response.token
    instanceReporting.defaults.headers.common["idtreeToken"] = response.token

    //Auth to other services
    if (interfaceType !== "SUPERVISOR") {
      const responseReporting: any = await store.dispatch(_authReporting())
      cookie.save("reportingToken", responseReporting, { path: "/" })
      instanceReporting.defaults.headers.common["reportingToken"] = responseReporting
    }
  } else {
    cookie.remove("firebaseToken", { path: "/" })
    cookie.remove("idtreeToken", { path: "/" })
    axios.defaults.headers.common["firebaseToken"] = null
    axios.defaults.headers.common["idtreeToken"] = null
  }

  //Init account au démarrage
  const _session: Session = new Session()

  //Init user object
  let user: User = new User()

  //Handle response
  if (response.user) {
    //Init account
    const account = new Account(response.account)
    const superAccount = new Account(account.isSuperAccount ? account : response.superAccount)

    //For superadmin seach account
    if (account.isSuperAccount) {
      interfaceType = "SUPERADMIN"
      const accounts: any = await store.dispatch(accountFetchLite_AsSuperadmin())
      store.dispatch(accountGet(accounts.error ? [] : accounts))
    }

    //Init new
    user = new User(response.user)

    //Set than user is connected
    if (!connectedAsSupervisor && authLevel > 0) {
      store.dispatch(userEditAndUpdate(user, "connectedAt", new Date().getTime()))
    }

    if (user.connexionMode) {
      _session.connexionMode = user.connexionMode
    }

    //Update sessions params
    _session.qvCode = response.qvCode ? response.qvCode : null
    _session.accountAllowedTemplates = account.allowedTemplates
    _session.accountColors = account.colors
    _session.accountId = account.id
    _session.accountIsChildren = account.superAccountId ? true : false
    _session.accountLevel = account.level
    _session.accountOptions = account.options
    _session.accountTemplateRule = account.templateRule
    _session.accountName = account.name
    _session.accountLastSubscriptionEnd = account.superAccountId ? null : account.lastSubscriptionEnd
    _session.isPasswordRequired = account.isPasswordRequired
    _session.isSelfService = account.isSelfService
    _session.isSuperAccount = account.isSuperAccount
    _session.authLevel = authLevel
    _session.connectedAsSuperadmin =
      interfaceType === "SUPERADMIN" || account.superAccountId === store.getState()._session.superaccountId
    _session.connectedAsSupervisor = connectedAsSupervisor
    _session.email = user.email ? user.email : ""
    _session.interfaceType = interfaceType
    _session.language = user.language ? user.language : "en"
    _session.participationMin = account.participationMin
    _session.phone = user.phone ? user.phone : ""
    _session.supervisorId = connectedAsSupervisor ? user.id : ""
    _session.supervisorLevel = connectedAsSupervisor ? user.level : 0
    _session.userFirstname = user.firstname
    _session.userId = interfaceType === "SUPERVISOR" ? "" : user.id
    _session.userLastname = user.lastname
    _session.userInitialLanguage = user.language.toLowerCase()
    _session.userOptions = user.options
    _session.userRole = connectedAsSupervisor ? "ADMIN" : user.role
    _session.userExcludedFilters = user.excludedFilters
    _session.userPasswordProvided = response.asAdmin || interfaceType !== "ADMIN" ? true : user.passwordProvided
    _session.modules = account.modules
    _session.invitationsRemaining = response.invitationsRemaining

    //Update superadmin account informations
    //If not superadmin interface keep existant informations
    _session.superaccountColor = superAccount.colors.brandPrimary
    _session.superaccountId = superAccount.id
    _session.superaccountImage = superAccount.options.image
    _session.superaccountName = superAccount.name

    //Load packs + subscriptions + remaining invitations
    if (
      _session.interfaceType !== "SUPERVISOR" &&
      _session.userRole === "ADMIN" &&
      (_session.connectedAsSuperadmin || !_session.superaccountId)
    ) {
      const packs: any = await store.dispatch(packFetch())
      const subscriptions: any = await store.dispatch(subscriptionFetch())

      store.dispatch(packGet(!packs.error ? packs : []))
      store.dispatch(subscriptionGet(!subscriptions.error ? subscriptions : []))
    }
  }

  //Save soure
  let source = searchParams.get("utm_source")
  const redirection = searchParams.get("redirection")
  if (!source) source = ""

  //If auth public
  //set ad options for session
  if (authLevel === 0) {
    _session.accountId = uuid()
    _session.accountAdsOptions = new AccountAdsOptions({
      SOURCE: source,
      CAMPAIGNID: searchParams.get("campaignid"),
      ADGROUPID: searchParams.get("adgroupid"),
      KEYWORD: searchParams.get("keyword"),
      GCLID: searchParams.get("gclid"),
      TARGETID: searchParams.get("targetid"),
      TRIAL_SOURCE: searchParams.get("trial_source"),
      TRIAL_PAGE: searchParams.get("trial_page")
    })

    //Get language if not defined
    if (!response.user.language) {
      const language = lg ? lg : navigator.language.split("-")[0]
      if (SESSION_LANGUAGES.find((x) => x.id === language)) {
        _session.language = language
      } else {
        _session.language = "en"
      }
    }
  }

  //Update language for the account
  i18n.changeLanguage(_session.language)

  //MAGIC BACKEND
  //Update ceils
  const currentCeil: any = ceils.find((x) => x.id === _session.accountId)
  if (currentCeil) {
    _session.accountOptions.ceilsBirthDate = currentCeil.ceilsBirthDate
    _session.accountOptions.ceilsCompanyWelcomeDate = currentCeil.ceilsCompanyWelcomeDate
  }

  //Update store
  store.dispatch(sessionGet(_session))

  //Reset action plan surveyReferenceID
  store.dispatch(actionPlanEditSurveyReferenceId(null))

  //Init active user (for action plan)
  store.dispatch(
    userActivate(
      new User({
        id: _session.userId,
        firstname: _session.userFirstname,
        lastname: _session.userLastname
      })
    )
  )

  //Go to home page
  if (interfaceType === "SUPERVISOR") {
    return "/supervisor/accounts"
  } else {
    if (authLevel === 0) {
      //Track conversion
      if (window["gtag"]) {
        window["gtag"]("event", "conversion", { send_to: "AW-970479852/6uS5CO7s3_sBEOyx4c4D" })
      }

      if (_session.language !== "fr") {
        setTimeout(() => {
          window.location.href = window.location.origin + "/onboarding/intro/0"
        }, 500)
      } else {
        //Content specify
        const content = searchParams.get("utm_content")

        //Content is specify go on the page to watch video
        //Else do classic onboarding
        if (content) {
          //Detect if mobile (trigger display error message)
          const isMobile = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1

          if (isMobile) {
            window.location.href = "https://www.id-tree.com/aide/"
          } else {
            return "/onboarding/video/" + content
          }
        } else if (redirection === "onboarding") {
          return "/onboarding/intro/0"
        } else {
          return "/onboarding/signup"
        }
      }
    } else {
      //Format population observers
      store.dispatch(filterGetObserverAttributes(user.populationsObserver))

      //Get destination Page
      //> first connexion go to signup to track conversion
      if (isFirstConnexion && env.REACT_APP_TRACK_LOGIN) {
        await setTimeout(() => {}, 500)
        window.open("https://www.id-tree.com/mp_signup/", "_self")
        return null
      }
      //If task load action plan and redirect to action plan page
      else if (redirection === "tasks") {
        return "/tasks/home"
      }
      //Else home page
      else {
        return "/home/account"
      }
    }
  }
}

export default handleLogin
